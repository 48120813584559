import React, { Component } from "react";

export default class contact extends Component {
  render() {
    return (
      <div>
        <div className="header" id='head'>
          <div className="container">
            <div className="contact-sectio section_career">
              <p className="text-bloack-24 text-white">Contact Us</p>
              <h2 class="heading_h2 u-mb-medium white contact-h">
                We are here to help.
              </h2>
            </div>
          </div>
        </div>
        <div className="container my-100">
          <div className="row">
            <div className="col-lg-3 col-sm-12"></div>
            <div className="col-lg-6 col-sm-12">
              <h3 class="font24 font-bold mb-5">Contact Information</h3>
              <div class="contact-form">
                <div>
                  <div class="row mt-4">
                    <div className="col-lg-6 col-sm-12">
                      <p class="text-grey">Mail Us</p>
                      <p>admin@rebounding.com</p>
                    </div>
                    {/* <div className="col-lg-6 col-sm-12 sm-mt">
                      <p class="text-grey">Call</p>
                      <p>+1 -----------</p>
                    </div> */}
                  </div>
                  <form class="my-5">
                    <div class="my-4 py-1">
                      <label class="text-grey" for="firstname">
                        First Name
                      </label>
                      <input
                        class="form-control"
                        placeholder="John Doe"
                        id="firstname"
                      />
                    </div>
                    <div class="my-4 py-1">
                      <label class="text-grey" for="email">
                        Email Address
                      </label>
                      <input
                        class="form-control"
                        placeholder="johndoe@example.com"
                        id="email"
                      />
                    </div>
                    <div class="my-4 py-1">
                      <label class="text-grey" for="email">
                        Type in your message
                      </label>
                      <textarea class="form-control" id="email"></textarea>
                    </div>
                    <div class="my-5">
                      <button
                        class="btn common-btn white font14 sm-mt"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12"></div>
          </div>
        </div>
      </div>
    );
  }
}
