import React from "react";
import Home from "./home";
import "./App.css";
import "./styles.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import privacy from "./privacy";
import contact from "./contact";
import Footer from "./footer";
import Navbar from "./navbar";

function App() {
  return (
    <div>
      <BrowserRouter>
        <div className='d-body'>
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/privacy" component={privacy} />
              <Route exact path="/contact" component={contact} />
            </Switch>
          </div>
          <Footer/>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
