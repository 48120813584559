import React, { Component } from "react";
import logo from "./assets/img/logo-white.png";
import { Link } from "react-router-dom";

export default class footer extends Component {
  render() {
    return (
      <div className="mt-auto">
        <footer className="footer">
          <div className="container flex u-mb-small">
            <div className="col-1-2">
              <div className="footer_content-card border-right w-clearfix">
                {/* <h3 className="heading_footer u-mb-small">Rebound your love life</h3> */}
                <a
                  href="#"
                  className="link-block-4 wide-tablet w-inline-block w-clearfix"
                >
                  <div className="footer_image u-mb-small"></div>
                </a>
                <div className="bodycopy_footer footer-up">
                  Develop and explore the skills necessary to get back in the
                  game..
                </div>
              </div>
            </div>
            <div className="col-1-2">
              <div className="footer_content-card right">
                <div className="footer_links u-mb-small">
                  <a href="/privacy/#head" className="footer_link">
                    Privacy
                  </a>
                </div>
                <div className="bodycopy_footer">
                  Questions about privacy and security?
                  <br />
                  Email us at<strong> </strong>
                  <a href="mailto:admin@rebound.biz" className="link-4">
                    <strong className="bold-text-6">admin@rebound.biz</strong>
                  </a>
                  <strong>.</strong>
                </div>
                <div className="bodycopy_footer last">
                  © 2020 Rebounding. All rights reserved.
                </div>
              </div>
            </div>
          </div>
          <div className="container center social-container">
            <a
              href="https://twitter.com/rebounding.biz"
              className="footer_social-link w-inline-block"
            >
              <i className="fa fa-twitter white font30"></i>
            </a>
            <a
              href="https://www.instagram.com/rebounding.biz/"
              className="footer_social-link w-inline-block"
            >
              <i className="fa fa-instagram white font30"></i>
            </a>
            <a
              href="https://www.facebook.com/rebounding.biz/"
              className="footer_social-link w-inline-block"
            >
              <i className="fa fa-facebook font30 white"></i>
            </a>
            <img src={logo} width="100" alt="rebounding" className="image-55" />
          </div>
        </footer>
      </div>
    );
  }
}
