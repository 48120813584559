import React, { Component } from "react";
import Footer from "./footer.js";

export default class privacy extends Component {
  render() {
    return (
      <div>
        <div className="header" id='head'>
          <div className="container">
            <div className="section_career">
              <h2 class="heading_h2 u-mb-medium white contact-h">
                Privacy Policy
              </h2>
            </div>
          </div>
        </div>
        <div className="my-5 py5 container">
          <p>
            Rebounding, LLC maintains the confidentiality of all written
            information exchanged between Subscriber and Coach. The following
            privacy policy explains the details of the data collected on and/or
            through the App.
          </p>
          <p>
            Rebounding, LLC is committed to protecting and safeguarding the
            privacy of each Subscriber. We also refrain from disclosing the
            identity of each Coach. We adhere to all mandatory reporting
            requirements, as well as any and all regulatory laws governing the
            oversight of a Life Coaching service. This policy describes how your
            personal information will be used.
          </p>
          <p>
            Collected Information
            <ul>
              <li>Information provided to register as a Subscriber</li>
              <li>Information submitted on our website for inquiries</li>
              <li>Information communicated between Subscriber and Coach</li>
            </ul>
          </p>
          <p>
            Personal Data Usage
            <ul>
              <li>Administration of the App</li>
              <li>Communication with the Coaches</li>
              <li>Coordination of care, when applicable</li>
              <li>Send requested cards and / or flowers</li>
              <li>Mandatory Reporting, when applicable</li>
              <li>Comments made by Subscriber</li>
              <li>Reviews submitted by Subscriber</li>
              <li>Follow-up requested by Subscriber or Coach</li>
            </ul>
          </p>
          <p>
            Information submitted through the landing page is kept confidential
            unless otherwise requested by the Subscriber.
          </p>
          <p>
            We do not provide personal information to marketers, nor do we sell
            information for marketing purposes.
          </p>
          <p>
            Disclosures <br />
            Every Coach employed by Rebounding, LLC has access to the personal
            information submitted by every Subscriber. There are no assigned
            Coaches. Every Coach provides continuity of care during the time
            they are logged in for support. This gives Rebounding, LLC the
            ability to provide 24/7 care at a time convenient for you. We have
            an Administrative Department that provides oversight of every
            interaction through the App. In the event communication becomes
            inappropriate or reaches a level of danger to self and / or danger
            to others concerns; our Administrative Department will adhere to our
            internal reporting requirements. All inappropriate communication
            will result in suspended Subscriber services. Danger to Self and /
            or Danger to Others comments or communication will result in
            immediate adherence to internal Duty to Warn and Coordination of
            Care practices.
          </p>
          <p>
            In addition, we will disclose information about you:
            <ul>
              <li>When required to do so by law</li>
              <li>
                When legal proceedings or prospective legal proceedings subpoena
                information
              </li>
            </ul>
          </p>
          <p>
            Security of Personal Data <br />
            Rebounding, LLC requires all Subscribers to submit an email address
            and personal password to access the App. We recommend this
            information be kept confidential in efforts to support the privacy
            of your communication with the Coaches. We take all reasonable
            technical precautions to prevent the loss, misuse, or alteration of
            your personal information. 
          </p>
          <p>
            Policy Amendments
            <br />
            Rebounding, LLC reserves the right to update this policy as needed.
            Every Subscriber will be required to submit a new acknowledgement
            upon revision.
          </p>
          <p>
            Contact
            <br />
            If you have any questions or concerns, please submit an email
            through our landing page or email Admin@rebounding.biz.
          </p>
          <p>
            Acknowledgment of the Privacy Policy includes acknowledging that all
            information submitted through Rebounding is maintained
            confidentially.  Rebounding cannot be held liable for unprotected
            access to the App through personal devices.
          </p>
        </div>
      </div>
    );
  }
}
