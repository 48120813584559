import React, { Component } from "react";
import goal from "./assets/img/goal.svg";
import chat from "./assets/img/chat.svg";
import cloud from "./assets/img/cloud.svg";
import love from "./assets/img/love.svg";
import time from "./assets/img/time.svg";
import validate from "./assets/img/validate.svg";
import video from "./assets/img/video.svg";
import banner from "./assets/img/banner.png";
import { isAndroid, isIOS } from "react-device-detect";
import bgDownloadSm from "./assets/img/bg-download-sm.png";
import bgDownloadLg from "./assets/img/bg-download-lg.png";
import bgFeatureSm from "./assets/img/bg-feature-sm.png";
import bgFeatureLg from "./assets/img/bg-feature-lg.png";

const PLAYSTORE_LINK =
  "https://play.google.com/store/apps/details?id=com.hollainc.rebounding";
const APPSTORE_LINK = "https://apps.apple.com/us/app/rebounding/id1610084135";
const navigateAppStores = () => {
  if (isAndroid) {
    window.open(PLAYSTORE_LINK, "_blank");
  } else {
    window.open(APPSTORE_LINK, "_blank");
  }
};

export default class home extends Component {
  render() {
    return (
      <div>
        <div className="section-36">
          <div className="div-block-33" id="head">
            <div className="html-embed-6 w-embed w-script">
              <h2 className="heading_h1 white u-mb-small">
                24/7 Life Coaching
              </h2>
            </div>
            <p className="paragraph-27">
              We help you navigate the unexpected trajectory of a break-up. Our
              Coaches are qualified to support you to victory over a failed
              relationship or broken marriage. We focus on helping you develop
              and explore the skills necessary to get back in the game
            </p>
            <a
              // href="#"
              className="button-3 hover w-button"
              onClick={() => {
                navigateAppStores();
              }}
            >
              Download
            </a>
          </div>
          {/* <div className="div-block-47"><img
        src={banner}
        sizes="(max-width: 750px) 100vw, (max-width: 767px) 750px, 100vw" alt="" /></div> */}
        </div>
        <div className="section-38">
          <div className="div-block-48">
            <img
              src={bgDownloadLg}
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 92vw, 100vw"
              alt=""
              className="image-81"
            />
            <img
              src={bgDownloadSm}
              sizes="(max-width: 479px) 93vw, (max-width: 767px) 95vw, 100vw"
              alt=""
              className="image-80"
            />
          </div>
          <div className="div-block-34">
            <div className="div-block-36">
              <h1 className="heading-39">
                One mobile app to help you develop and explore the skills
                necessary to get back in the game.
              </h1>
            </div>
          </div>
          <div className="div-block-35">
            <div className="w-row">
              <div className="column-21 w-col w-col-4">
                <img src={chat} alt="" />
                <p className="paragraph-29">
                  Real-time life coaching within seconds
                </p>
              </div>
              <div className="column-22 w-col w-col-4">
                <img src={video} alt="" className="image-75" />
                <p className="paragraph-30">
                  24/7 access to encouragement and motivation at your fingertips
                </p>
              </div>
              <div className="column-23 w-col w-col-4">
                <img src={validate} alt="" className="image-76" />
                <p className="paragraph-31">
                  Well-validated, life changing activities curated by your coach
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-37" id="approach">
          <div className="div-block-49">
            <img
              src={bgFeatureLg}
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 92vw, 100vw"
              alt=""
              className="image-83"
            />
            <img
              src={bgFeatureSm}
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 100vw"
              alt=""
              className="image-82"
            />
          </div>
          <div className="div-block-37">
            <div className="div-block-38">
              <h2 className="heading-40">
                Personalized interactions, for every individual.
              </h2>
            </div>
          </div>
          <div className="div-block-39">
            <div className="w-row">
              <div className="column-24 w-col w-col-3 w-col-medium-6">
                <img src={love} alt="" />
                <p className="paragraph-33">Goal progress</p>
                <p className="paragraph-37">
                  Set your goals and see how you progress
                </p>
              </div>
              <div className="column-25 w-col w-col-3 w-col-medium-6">
                <img src={goal} alt="" />
                <p className="paragraph-34">Track goals</p>
                <p className="paragraph-38">
                  Set your targets, see how far you've come
                </p>
              </div>
              <div className="column-26 w-col w-col-3 w-col-medium-6">
                <img src={cloud} alt="" />
                <p className="paragraph-35">Set action steps</p>
                <p className="paragraph-39">
                  Get more from rebounding, set action steps to accomplish
                </p>
              </div>
              <div className="column-27 w-col w-col-3 w-col-medium-6">
                <img src={time} alt="" />
                <p className="paragraph-36">Exercises</p>
                <p className="paragraph-40">
                  Rebound with personalized exercises
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Youtube */}
        {/* <div>
          <div className="div-block-40" id="coaches">
            <p className="paragraph-41">Spotlight on life coaching</p>
            <h2 className="heading-41">Our methods explained</h2>
            <div className="html-embed-9 w-embed w-iframe">
              <iframe
                src="https://www.youtube.com/embed/HV_g-e-c9BY"
                width="940"
                height="530"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-2 w-embed w-iframe">
              <iframe
                src="https://www.youtube.com/embed/HV_g-e-c9BY"
                width="675"
                height="528.75"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-2 _2 w-embed w-iframe">
              <iframe
                src="https://www.youtube.com/embed/HV_g-e-c9BY"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      */}

        <div className="section-40">
          <div className="div-block-51">
            <div>
              <h2 className="heading-43">
                Navigate the unexpected trajectory of a break-up
              </h2>
            </div>
            <div className="column-29 w-col w-col-4">
              <p className="paragraph-46 counter">80</p>
              <p className="paragraph-52">%</p>
              <p className="paragraph-47">Rebounded</p>
              <p className="paragraph-48 counter">20</p>
              <p className="paragraph-48">%</p>
              <p className="paragraph-49">Study process</p>
            </div>
            <p className="paragraph-50">
              Percentage of members who show significant improvements in their
              love life after 2 weeks of coaching.
            </p>
          </div>
          <div className="div-block-45">
            <div className="columns-11 w-row">
              <div className="w-col w-col-8">
                <h2 className="heading-43">
                  Navigate the unexpected trajectory of a break-up
                </h2>
              </div>
              <div className="column-29 w-col w-col-4">
                <p className="paragraph-46 counter">80</p>
                <p className="paragraph-52">%</p>
                <p className="paragraph-47">Rebounded</p>
                <p className="paragraph-48 counter">20</p>
                <p className="paragraph-48">%</p>
                <p className="paragraph-49">Study process</p>
              </div>
            </div>
            <p className="paragraph-50">
              Percentage of members who show significant improvements in their
              love life after 2 weeks of coaching.
            </p>
          </div>
        </div>
        <div className="section-41">
          <div className="div-block-46">
            <p className="paragraph-51">
              {" "}
              Certified Coaches (Life Coaches) interested in helping others win
            </p>
            <a
              href="mailto:admin@rebound.biz"
              className="button-4 hover w-button"
            >
              Contact Admin@rebounding.biz
            </a>
          </div>
        </div>
        <div className="html-embed-5 w-embed w-script">
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js"></script>
          <script src="https://cdn.jsdelivr.net/jquery.counterup/1.0/jquery.counterup.min.js"></script>
        </div>
      </div>
    );
  }
}
