import React, { Component } from "react";
import "./nav.css";
import logo from "./assets/img/logo-full.png";
import { Link } from "react-router-dom";

export default class navbar extends Component {
  render() {
    return (
      <div>
        <nav class="navbar navbar-light navbar-expand-lg fixed-top ">
          <div class="container">
            <span class="navbar-brand m-0 p-0">
              <Link to="/#head">
                <img src={logo} />
              </Link>
            </span>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="nav text-uppercase align-items-center ml-auto">
                <li class="nav-item">
                  <a class="nav-link active" href="/#head">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#approach">
                    Our Approach
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#coaches">
                    Our Coaches
                  </a>
                </li>

                <li class="nav-item">
                  {/* <a class="nav-link" href="/contact/#head">
                   */}
                  <a class="nav-link" href="/contact">
                    Contact
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a target="_blank" href="https://twitter.com/rebounding">
                    <i class=" fa fa-twitter fade-in" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://facebook.com/rebounding">
                    <i class=" fa fa-facebook-square" aria-hidden="true"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
